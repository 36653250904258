<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        Select the correct expression for the equilibrium constant for the following reaction:
      </p>

      <p class="mb-2 pl-8">
        <chemical-latex content="A(s) + 3 B(l) <=> 2 C(aq) + D(aq)" />
      </p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-n3">
        <div v-for="option in options" :key="option.value" class="mb-2">
          <v-radio class="d-inline-block pt-3" :value="option.value" />
          <stemble-latex :content="option.condition" />
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question102',
  components: {StembleLatex, ChemicalLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
      },
      options: [
        {
          condition:
            '$\\text{K}_\\text{c} = \\displaystyle{\\frac{\\ce{[A][B]}^3}{\\ce{[C]}^2\\ce{[D]}}}$',
          value: '1',
        },
        {
          condition:
            '$\\text{K}_\\text{c} = \\displaystyle{\\frac{\\ce{[C]}^2\\ce{[D]}}{\\ce{[A][B]}^3}}$',
          value: '2',
        },
        {
          condition: '$\\text{K}_\\text{c} = \\displaystyle{\\frac{1}{\\ce{[C][D]}}}$',
          value: '3',
        },
        {
          condition: '$\\text{K}_\\text{c} = \\displaystyle{\\frac{1}{\\ce{[C]}^2\\ce{[D]}}}$',
          value: '4',
        },
        {
          condition: '$\\text{K}_\\text{c} = \\displaystyle{\\ce{[C]}^2\\ce{[D]}}$',
          value: '5',
        },
        {
          condition: '$\\text{K}_\\text{c} = \\displaystyle{\\frac{\\ce{[C][D]}}{\\ce{[A][B]}}}$',
          value: '6',
        },
      ],
    };
  },
};
</script>
